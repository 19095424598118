import { VbServiceFactory } from '@/services/VbServiceFactory'
import { ServiceFactory } from '@/services/ServiceFactory'
// import VbExlusiveVideo from './exclusive-video'
import moment from 'moment'
const _projectService = VbServiceFactory.get('ProjectService')
const _barsService = ServiceFactory.get('BarsService')

export default {
  name: 'VbProject',
  // components: {
  //   VbExlusiveVideo
  // },
  data () {
    return {
      bars: [],
      e1: 1,
      menu: false,
      menu2: false,
      loading: false,
      selectedType: this.type,
      // type: {
      //   id: '576429a2-2db7-4058-b0a6-10e4de96cf46',
      //   name: 'Video exclusivo'
      // },
      projectTypeItems: ['Proyecto interno', 'Proyecto externo'],
      // contractType: [
      //   {
      //     id: '576429a2-2db7-4058-b0a6-10e4de96cf46',
      //     name: 'Video exclusivo'
      //   },
      //   {
      //     id: '2414b9d2-6875-4f01-bdb7-0bcd21dc32c5',
      //     name: 'Manejo de estrategia de la plataforma Tiktok'
      //   }
      // ],
      paymentFrequencyItems: ['Mensual'],
      contractPeriodItems: [1, 2, 3, 4, 5, 6],
      paymentTypeItems: ['Cuotas', 'Al contado'],
      platformTypeItems: ['TikTok'],
      postProject: {
        'account': {
          'vbContractTypeId': '',
          'projectType': 'Proyecto interno',
          'businessName': '',
          'businessType': '',
          'businessId': '',
          'title': '',
          'description': ''
        },
        'profile': {
          'platformType': 'TikTok',
          'accountUrl': '',
          'bio': '',
          'brief': '',
          'linkTree': '',
          'name': '',
          'email': '',
          'cellphone': '',
          'socialMedia': ''
        },
        'contract': {
          'paymentFrequency': '',
          'contractPeriod': '',
          'paymentType': '',
          'quotas': '',
          'amount': '',
          'url': '',
          'startedAt': '',
          'finishedAt': ''
        },
        'billing': []
      },
      socialMediaObj: {
        'tiktok': '',
        'facebook': '',
        'instagram': ''
      },
      socialMediaSelected: [],
      socialMediaItems: [
        {
          name: 'Instagram',
          url: '',
          icon: 'fab fa-instagram'
        },
        {
          name: 'Facebook',
          url: '',
          icon: 'fab fa-facebook'
        },
        {
          name: 'Página web',
          url: '',
          icon: 'fas fa-globe'
        }
      ],
      thumbnail: {
        image: null
      },
      profile: {
        id: '',
        imageType: 'profile',
        image: null
      },
      paymentDates: []
    }
  },
  props: [
    'contractType',
    'type'
  ],
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      console.log('test')
      moment.locale('es')
      try {
        const barsResponse = await _barsService.listBar()
        console.log(barsResponse)
        this.bars = barsResponse.bars
      } catch (error) {
        this.$notify({
          type: 'error',
          text: error.msg
        })
        console.log(error)
      }
      // const response = await _projectService.index()
      // console.log(response)
    },
    // async submit () {
    //   console.log(this.postExclusiveVideo)
    //   this.postExclusiveVideo.amount = parseInt(this.postExclusiveVideo.amount)
    //   const response = await _projectService.exclusiveVideo(this.postExclusiveVideo)
    //   console.log(response)
    // },
    async submit () {
      this.loading = true
      try {
        this.postProject.account.vbContractTypeId = this.selectedType.id
        console.log(this.postProject)
        this.postProject.profile.cellphone = parseInt(this.postProject.profile.cellphone)
        this.postProject.contract.quotas = parseInt(this.postProject.contract.quotas)
        this.postProject.contract.amount = parseInt(this.postProject.contract.amount)
        this.postProject.billing = this.paymentDates
        const response = await _projectService.create(this.postProject)
        console.log(response)
        // this.$notify({
        //   type: 'success',
        //   text: response.msg
        // })
        this.profile.id = response.profile.id
        const imageResponse = await _projectService.image(this.profile)
        console.log(imageResponse)
        this.$swal(
          'Agregado Correctamente',
          '',
          'success'
        ).then(async (result) => {
          this.$router.push({ name: 'reserbar', params: { view: 'videobrand' } }).catch(() => {})
        })
        this.loading = false
      } catch (error) {
        this.$notify({
          type: 'error',
          text: error.msg
        })
        console.log(error)
        this.loading = false
      }
    },
    goBack () {
      this.$router.push({ name: 'billing' }).catch(() => {})
    },
    projectTypeFunc (e) {
      // console.log('project')
      console.log(e)
      if (e === 'Proyecto interno') {
        this.postProject.account.businessName = ''
        this.postProject.account.businessType = ''
      } else {
        this.postProject.account.businessId = ''
      }
    },
    contractTypeFunc () {
      // console.log(e)
      this.$emit('changeType')
      // this.type = e
      // if (e.name === 'Video exclusivo') {
      //   this.postExclusiveVideo.vbContractTypeId = e.id
      // } else {
      //   this.postProject.account.vbContractTypeId = e.id
      // }
    },
    paymentTypeSelect (e) {
      if (e === 'Cuotas') {
        this.postProject.contract.quotas = '2'
      } else {
        this.postProject.contract.quotas = '1'
      }
    },
    finishedAtSelect () {
      this.menu2 = false
      if (this.postProject.contract.contractPeriod === '') {
        this.postProject.contract.finishedAt = this.postProject.contract.startedAt
      } else {
        const finDate = moment(this.postProject.contract.startedAt).add(this.postProject.contract.contractPeriod, 'M').format('YYYY-MM-DD')
        this.postProject.contract.finishedAt = finDate
      }

      this.paymentDates = []

      var totalPayments = 0
      var amountDivider = 1
      if (this.postProject.contract.paymentType === 'Cuotas') {
        totalPayments = this.postProject.contract.contractPeriod + 1
        amountDivider = 2
      } else {
        totalPayments = this.postProject.contract.contractPeriod
        amountDivider = 1
      }
      var date = moment(this.postProject.contract.startedAt)
      for (let index = 0; index < totalPayments; index++) {
        if (index === 0 || index === totalPayments - 1) {
          console.log('primera o ultima')
          this.paymentDates.push(
            {
              paymentDate: moment(date).add(index, 'M').format('YYYY-MM-DD'),
              amount: this.postProject.contract.amount / amountDivider,
              month: moment(date).add(index, 'M').format('MMMM'),
              menu: false
            }
          )
        } else {
          console.log('medio')
          this.paymentDates.push(
            {
              paymentDate: moment(date).add(index, 'M').format('YYYY-MM-DD'),
              amount: this.postProject.contract.amount,
              month: moment(date).add(index, 'M').format('MMMM'),
              menu: false
            }
          )
        }
      }
    },
    socialMediaFunc () {
      console.log(this.socialMediaSelected)
    },
    viewsocial () {
      console.log(this.postProject.profile)
      this.postProject.profile.socialMedia = this.socialMediaSelected
      console.log(this.socialMediaSelected)
    },
    handleThumbnailUpload (e) {
      // console.log(e)
      this.profile.image = e.target.files[0]
      // console.log(e.target.files[0])
      this.previewThumbnail(e)
    },
    previewThumbnail (event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.readAsDataURL(input.files[0])
        reader.onload = (e) => {
          let img = new Image()
          img.src = e.target.result
          this.thumbnail.image = e.target.result
          // console.log(this.thumbnail.image)
        }
      }
    }
  }
}
