import { VbServiceFactory } from '@/services/VbServiceFactory'
// import { ServiceFactory } from '@/services/ServiceFactory'
const _projectService = VbServiceFactory.get('ProjectService')
// const _barsService = ServiceFactory.get('BarsService')

export default {
  name: 'VbExclusiveVideo',
  data () {
    return {
      menu2: false,
      selectedType: this.type,
      loading: false,
      // contractType: [
      //   {
      //     id: '576429a2-2db7-4058-b0a6-10e4de96cf46',
      //     name: 'Video exclusivo'
      //   },
      //   {
      //     id: '2414b9d2-6875-4f01-bdb7-0bcd21dc32c5',
      //     name: 'Manejo de estrategia de la plataforma Tiktok'
      //   }
      // ],
      postExclusiveVideo: {
        'vbContractTypeId': '',
        'businessName': '',
        'businessId': null,
        'amount': '',
        'paymentMethod': '',
        'videoUrl': '',
        'title': '',
        'author': '',
        'publicated_at': (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      },
      thumbnail: {
        image: null
      }
    }
  },
  props: [
    'contractType',
    'type'
  ],
  mounted () {
    // this.initialize()
  },
  methods: {
    async initialize () {
      console.log('test')
      // try {
      //   const barsResponse = await _barsService.listBar()
      //   console.log(barsResponse)
      //   this.bars = barsResponse.bars
      // } catch (error) {
      //   this.$notify({
      //     type: 'error',
      //     text: error.msg
      //   })
      //   console.log(error)
      // }
      // const response = await _projectService.index()
      // console.log(response)
    },
    async submit () {
      this.loading = true
      try {
        console.log(this.postExclusiveVideo)
        this.postExclusiveVideo.vbContractTypeId = this.selectedType.id
        this.postExclusiveVideo.amount = parseInt(this.postExclusiveVideo.amount)
        const response = await _projectService.exclusiveVideo(this.postExclusiveVideo)
        console.log(response)
        // this.$notify({
        //   type: 'success',
        //   text: response.msg
        // })
        this.$swal(
          'Agregado Correctamente',
          '',
          'success'
        ).then(async (result) => {
          // this.$router.push({ name: 'billing' }).catch(() => {})
          this.$router.push({ name: 'reserbar', params: { view: 'videobrand' } }).catch(() => {})
        })
        this.loading = false
      } catch (error) {
        this.$notify({
          type: 'error',
          text: error.msg
        })
        console.log(error)
        this.loading = false
      }
    },
    goBack () {
      this.$router.push({ name: 'billing' }).catch(() => {})
    },
    contractTypeFunc () {
      // console.log(e)
      this.$emit('changeType')
      // if (e.name === 'Video exclusivo') {
      // this.postExclusiveVideo.vbContractTypeId = e.id
      // } else {
      //   this.postProject.account.vbContractTypeId = e.id
      // }
    },
    handleThumbnailUpload (e) {
      // console.log(e)
      // this.postData.thumbnailFile = e.target.files[0]
      // console.log(e.target.files[0])
      this.previewThumbnail(e)
    },
    previewThumbnail (event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.readAsDataURL(input.files[0])
        reader.onload = (e) => {
          let img = new Image()
          img.src = e.target.result
          this.thumbnail.image = e.target.result
          // console.log(this.thumbnail.image)
        }
      }
    }
  }
}
