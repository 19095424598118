import { render, staticRenderFns } from "./VbBillingComponent.html?vue&type=template&id=15f14846&"
import script from "./VbBillingComponent.js?vue&type=script&lang=js&"
export * from "./VbBillingComponent.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports