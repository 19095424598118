var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-0"},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"dark":"","color":"#ff5a5f","small":""},on:{"click":_vm.contractTypeFunc}},[_c('v-icon',[_vm._v("fas fa-arrow-left")])],1)],1),_c('v-col',{attrs:{"cols":"11"}},[_c('h1',[_vm._v("Manejo de estrategia de la plataforma Tiktok")])])],1),_c('v-stepper',{model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"complete":_vm.e1 > 1,"step":"1"}},[_vm._v(" Nueva empresa ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.e1 > 2,"step":"2"}},[_vm._v(" Creación de perfil ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"3"}},[_vm._v(" Firma de contrato ")])],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();_vm.e1 = 2}}},[_c('v-card',{staticClass:"mb-12 pt-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Contract","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.contractType,"rules":[function (v) { return !!v || 'Item is required'; }],"item-text":"name","item-value":"name","label":"Tipo de contrato","placeholder":"Tipo de contrato","outlined":"","persistent-placeholder":"","readonly":"","dense":"","return-object":"","prepend-icon":"fas fa-file"},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"ProjectType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.projectTypeItems,"rules":[function (v) { return !!v || 'Item is required'; }],"label":"Tipo de proyecto","error-messages":errors,"persistent-placeholder":"","prepend-icon":"fas fa-file","placeholder":"Tipo de proyecto","outlined":"","dense":""},on:{"change":_vm.projectTypeFunc},model:{value:(_vm.postProject.account.projectType),callback:function ($$v) {_vm.$set(_vm.postProject.account, "projectType", $$v)},expression:"postProject.account.projectType"}})]}}],null,true)})],1)],1),(_vm.postProject.account.projectType === 'Proyecto interno')?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"BusinessSelect","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Seleccionar negocio","items":_vm.bars,"error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","required":"","item-text":"name","item-value":"zbBarId","placeholder":"Seleccionar negocio","outlined":"","persistent-placeholder":"","prepend-icon":"fas fa-building","dense":""},model:{value:(_vm.postProject.account.businessId),callback:function ($$v) {_vm.$set(_vm.postProject.account, "businessId", $$v)},expression:"postProject.account.businessId"}})]}}],null,true)})],1)],1):_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"BusinessName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre de negocio","required":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","prepend-icon":"fas fa-building","placeholder":"Nombre de negocio","outlined":"","dense":""},model:{value:(_vm.postProject.account.businessName),callback:function ($$v) {_vm.$set(_vm.postProject.account, "businessName", $$v)},expression:"postProject.account.businessName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"BusinessType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Tipo de negocio","required":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","prepend-icon":"fas fa-building","placeholder":"Tipo de negocio","outlined":"","dense":""},model:{value:(_vm.postProject.account.businessType),callback:function ($$v) {_vm.$set(_vm.postProject.account, "businessType", $$v)},expression:"postProject.account.businessType"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Título","required":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","placeholder":"Título","persistent-placeholder":"","prepend-icon":"fas fa-pen","outlined":"","dense":""},model:{value:(_vm.postProject.account.title),callback:function ($$v) {_vm.$set(_vm.postProject.account, "title", $$v)},expression:"postProject.account.title"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Descripción","auto-grow":"","outlined":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","prepend-icon":"fas fa-pen","rows":"2","row-height":"20"},model:{value:(_vm.postProject.account.description),callback:function ($$v) {_vm.$set(_vm.postProject.account, "description", $$v)},expression:"postProject.account.description"}})]}}],null,true)})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":invalid}},[_vm._v(" Siguiente ")])],1)],1)],1)]}}])})],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();_vm.e1 = 3}}},[_c('v-card',{staticClass:"mb-12 pt-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"PlatformType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.platformTypeItems,"rules":[function (v) { return !!v || 'Item is required'; }],"label":"Tipo de plataforma","persistent-placeholder":"","prepend-icon":"fas fa-photo-video","placeholder":"Tipo de plataforma","outlined":"","dense":""},model:{value:(_vm.postProject.profile.platformType),callback:function ($$v) {_vm.$set(_vm.postProject.profile, "platformType", $$v)},expression:"postProject.profile.platformType"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"AccountUrl","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Url de la cuenta","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","required":"","persistent-placeholder":"","prepend-icon":"fas fa-link","placeholder":"Url de la cuenta","outlined":"","dense":""},model:{value:(_vm.postProject.profile.accountUrl),callback:function ($$v) {_vm.$set(_vm.postProject.profile, "accountUrl", $$v)},expression:"postProject.profile.accountUrl"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Bio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Bio","auto-grow":"","persistent-placeholder":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","prepend-icon":"fas fa-pen","outlined":"","rows":"2","row-height":"20"},model:{value:(_vm.postProject.profile.bio),callback:function ($$v) {_vm.$set(_vm.postProject.profile, "bio", $$v)},expression:"postProject.profile.bio"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Brief","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Brief (enlace de drive)","required":"","persistent-placeholder":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","prepend-icon":"fas fa-link","placeholder":"Brief (enlace de drive)","outlined":"","dense":""},model:{value:(_vm.postProject.profile.brief),callback:function ($$v) {_vm.$set(_vm.postProject.profile, "brief", $$v)},expression:"postProject.profile.brief"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Linktree","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"LinkTree","persistent-placeholder":"","error-messages":errors,"persistent-hint":"","prepend-icon":"fas fa-link","placeholder":"LinkTree","outlined":"","dense":""},model:{value:(_vm.postProject.profile.linkTree),callback:function ($$v) {_vm.$set(_vm.postProject.profile, "linkTree", $$v)},expression:"postProject.profile.linkTree"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{staticClass:"scrollbar mx-auto ml-1",attrs:{"max-width":"260"}},[_c('v-card-text',{staticClass:"scrollbar"},[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":_vm.thumbnail.image,"id":"bannerImg","aspect-ratio":1/1}},[_c('div',{staticClass:"banner-button"},[_c('v-btn',{attrs:{"icon":"","color":"primary"}},[_c('v-icon',[_vm._v("fas fa-camera")])],1),_c('input',{ref:"file",staticClass:"input-banner",attrs:{"id":"thumbFile","accept":"image/*","type":"file"},on:{"change":_vm.handleThumbnailUpload}})],1)])],1)],1)],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre","required":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","prepend-icon":"fas fa-user","placeholder":"Nombre","outlined":"","dense":""},model:{value:(_vm.postProject.profile.name),callback:function ($$v) {_vm.$set(_vm.postProject.profile, "name", $$v)},expression:"postProject.profile.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Cellphone","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Teléfono","required":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","prepend-icon":"fas fa-phone","placeholder":"Teléfono","outlined":"","dense":""},model:{value:(_vm.postProject.profile.cellphone),callback:function ($$v) {_vm.$set(_vm.postProject.profile, "cellphone", $$v)},expression:"postProject.profile.cellphone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Correo electrónico","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","required":"","persistent-placeholder":"","prepend-icon":"fas fa-envelope","placeholder":"Correo electrónico","outlined":"","dense":""},model:{value:(_vm.postProject.profile.email),callback:function ($$v) {_vm.$set(_vm.postProject.profile, "email", $$v)},expression:"postProject.profile.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.socialMediaItems,"item-text":"name","return-object":"","persistent-placeholder":"","prepend-icon":"fas fa-link","menu-props":{ maxHeight: '400' },"label":"Redes sociales","multiple":"","dense":"","outlined":"","persistent-hint":""},on:{"change":_vm.socialMediaFunc},model:{value:(_vm.socialMediaSelected),callback:function ($$v) {_vm.socialMediaSelected=$$v},expression:"socialMediaSelected"}})],1)],1)],1),_vm._l((_vm.socialMediaSelected),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":item.name,"rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":item.name,"error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","prepend-icon":item.icon,"required":"","placeholder":item.name,"outlined":"","dense":""},model:{value:(item.url),callback:function ($$v) {_vm.$set(item, "url", $$v)},expression:"item.url"}})]}}],null,true)})],1)})],2)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.e1 = 1}}},[_vm._v(" Anterior ")]),_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":invalid},on:{"click":_vm.viewsocial}},[_vm._v(" Siguiente ")])],1)],1)],1)]}}])})],1),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-card',{staticClass:"mb-12 mt-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"PaymentFrequency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"rules":[function (v) { return !!v || 'Item is required'; }],"items":_vm.paymentFrequencyItems,"label":"Frecuencia de pago","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","prepend-icon":"fas fa-file-invoice-dollar","placeholder":"Frecuencia de pago","outlined":"","dense":""},model:{value:(_vm.postProject.contract.paymentFrequency),callback:function ($$v) {_vm.$set(_vm.postProject.contract, "paymentFrequency", $$v)},expression:"postProject.contract.paymentFrequency"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"ContractPeriod","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"rules":[function (v) { return !!v || 'Item is required'; }],"items":_vm.contractPeriodItems,"error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","prepend-icon":"fas fa-file","label":"Cantidad de meses del contrato","placeholder":"Cantidad de meses del contrato","outlined":"","dense":"","suffix":"Meses"},model:{value:(_vm.postProject.contract.contractPeriod),callback:function ($$v) {_vm.$set(_vm.postProject.contract, "contractPeriod", $$v)},expression:"postProject.contract.contractPeriod"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"PaymentType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"rules":[function (v) { return !!v || 'Item is required'; }],"items":_vm.paymentTypeItems,"error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","prepend-icon":"fas fa-wallet","label":"Tipo de pago","placeholder":"Tipo de pago","outlined":"","dense":""},on:{"change":_vm.paymentTypeSelect},model:{value:(_vm.postProject.contract.paymentType),callback:function ($$v) {_vm.$set(_vm.postProject.contract, "paymentType", $$v)},expression:"postProject.contract.paymentType"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"Quotas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Número de cuotas","required":"","readonly":"","persistent-placeholder":"","prepend-icon":"fas fa-hashtag","placeholder":"Número de cuotas","outlined":"","dense":"","suffix":"Cuotas"},model:{value:(_vm.postProject.contract.quotas),callback:function ($$v) {_vm.$set(_vm.postProject.contract, "quotas", $$v)},expression:"postProject.contract.quotas"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"ContractAmount","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Pago mensual","required":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","prepend-icon":"fas fa-dollar-sign","placeholder":"Pago mensual","outlined":"","dense":"","suffix":"Bs."},model:{value:(_vm.postProject.contract.amount),callback:function ($$v) {_vm.$set(_vm.postProject.contract, "amount", $$v)},expression:"postProject.contract.amount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"ContractUrl","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Link drive del contrato","required":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","prepend-icon":"fas fa-link","placeholder":"Link drive del contrato","outlined":"","dense":""},model:{value:(_vm.postProject.contract.url),callback:function ($$v) {_vm.$set(_vm.postProject.contract, "url", $$v)},expression:"postProject.contract.url"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"StartedAt","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de inicio del contrato y pago","prepend-icon":"fas fa-calendar","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","placeholder":"Fecha de inicio del contrato y pago","readonly":"","outlined":"","dense":""},model:{value:(_vm.postProject.contract.startedAt),callback:function ($$v) {_vm.$set(_vm.postProject.contract, "startedAt", $$v)},expression:"postProject.contract.startedAt"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":_vm.finishedAtSelect},model:{value:(_vm.postProject.contract.startedAt),callback:function ($$v) {_vm.$set(_vm.postProject.contract, "startedAt", $$v)},expression:"postProject.contract.startedAt"}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"FinishedAt","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de finalización del contrato","prepend-icon":"fas fa-calendar","readonly":"","persistent-placeholder":"","placeholder":"Fecha de finalización del contrato","outlined":"","readonly":"","dense":""},model:{value:(_vm.postProject.contract.finishedAt),callback:function ($$v) {_vm.$set(_vm.postProject.contract, "finishedAt", $$v)},expression:"postProject.contract.finishedAt"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.postProject.contract.finishedAt),callback:function ($$v) {_vm.$set(_vm.postProject.contract, "finishedAt", $$v)},expression:"postProject.contract.finishedAt"}})],1)],1)],1),_c('v-row',_vm._l((_vm.paymentDates),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"4"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.month)+" ")]),_c('v-card-text',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":'PaymentDate'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de pago","prepend-icon":"fas fa-calendar","readonly":"","persistent-placeholder":"","placeholder":"Fecha de pago","outlined":"","readonly":"","dense":""},model:{value:(item.paymentDate),callback:function ($$v) {_vm.$set(item, "paymentDate", $$v)},expression:"item.paymentDate"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(item.menu),callback:function ($$v) {_vm.$set(item, "menu", $$v)},expression:"item.menu"}},[_c('v-date-picker',{on:{"input":function($event){item.menu = false}},model:{value:(item.paymentDate),callback:function ($$v) {_vm.$set(item, "paymentDate", $$v)},expression:"item.paymentDate"}})],1),_c('div',[_vm._v(" Monto: "+_vm._s(item.amount)+" ")])],1)],1)],1)}),1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.e1 = 2}}},[_vm._v(" Anterior ")]),_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.loading,"disabled":invalid}},[_vm._v(" Guardar ")])],1)],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }