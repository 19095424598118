import { VbServiceFactory } from '@/services/VbServiceFactory'
// import { ServiceFactory } from '@/services/ServiceFactory'
import VbExlusiveVideo from './exclusive-video'
import VbProject from './platform-manage'
const _projectService = VbServiceFactory.get('ProjectService')
// const _barsService = ServiceFactory.get('BarsService')

export default {
  name: 'VbBillingProject',
  components: {
    VbExlusiveVideo,
    VbProject
  },
  data () {
    return {
      // bars: [],
      // e1: 1,
      // menu2: false,
      type: {
        // id: '576429a2-2db7-4058-b0a6-10e4de96cf46',
        // name: 'Video exclusivo'
      },
      newProjectType: '',
      // projectTypeItems: ['Proyecto interno', 'Proyecto externo'],
      contractType: []
      // paymentFrequencyItems: ['Mensual'],
      // contractPeriodItems: [1, 2, 3],
      // paymentTypeItems: ['Cuotas'],
      // platformTypeItems: ['Tiktok'],
      // postExclusiveVideo: {
      //   'vbContractTypeId': '',
      //   'businessName': '',
      //   'businessId': null,
      //   'amount': null,
      //   'paymentMethod': '',
      //   'videoUrl': '',
      //   'title': '',
      //   'author': '',
      //   'publicated_at': (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      // },
      // postProject: {
      //   'account': {
      //     'vbContractTypeId': '',
      //     'projectType': 'Proyecto interno',
      //     'businessName': 'Kalakitas',
      //     'businessType': 'Restaurante',
      //     'businessId': '',
      //     'title': 'nueva cuenta',
      //     'description': 'descripccion de nueva cuenta'
      //   },
      //   'profile': {
      //     'platformType': 'Tiktok',
      //     'accountUrl': 'https://tiktok.com',
      //     'bio': 'cuenta de gastronomia, etc.',
      //     'brief': 'https://drive.com/1651df321',
      //     'linkTree': 'https://bitly.com/kalakitas',
      //     'name': 'Juan perez',
      //     'email': 'juan@mail.com',
      //     'cellphone': 78946155,
      //     'socialMedia': 'http:twitter.com/juanper'
      //   },
      //   'contract': {
      //     'paymentFrequency': 'Mensual',
      //     'contractPeriod': 3,
      //     'paymentType': 'Cuotas',
      //     'quotas': 2,
      //     'amount': 350,
      //     'url': 'https://drive.com/18984ada5',
      //     'startedAt': '2023-07-21',
      //     'finishedAt': '2023-10-21'
      //   }
      // }
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      console.log('test')
      try {
      //   const barsResponse = await _barsService.listBar()
      //   console.log(barsResponse)
      //   this.bars = barsResponse.bars
        const responseContracts = await _projectService.getContractTypes()
        console.log(responseContracts)
        this.contractType = responseContracts.contractTypes
      } catch (error) {
        this.$notify({
          type: 'error',
          text: error.msg
        })
        console.log(error)
      }
      // const response = await _projectService.index()
      // console.log(response)
    },
    // async submit () {
    //   console.log(this.postExclusiveVideo)
    //   this.postExclusiveVideo.amount = parseInt(this.postExclusiveVideo.amount)
    //   const response = await _projectService.exclusiveVideo(this.postExclusiveVideo)
    //   console.log(response)
    // },
    // async submitProject () {
    //   console.log(this.postProject)
    //   const response = await _projectService.create(this.postProject)
    //   console.log(response)
    // },
    // goBack () {
    //   this.$router.push({ name: 'billing' }).catch(() => {})
    // },
    contractTypeFunc () {
      // console.log(e)
      this.type = {}
      // if (e.name === 'Video exclusivo') {
      //   this.postExclusiveVideo.vbContractTypeId = e.id
      // } else {
      //   this.postProject.account.vbContractTypeId = e.id
      // }
    },
    goBack () {
      this.$router.push({ name: 'reserbar', params: { view: 'videobrand' } }).catch(() => {})
    },
    selectProject (t) {
      // alert(t)
      this.type = t
      this.newProjectType = t
      console.log(this.type)
    }
  }
}
