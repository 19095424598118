var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"dark":"","color":"#ff5a5f","small":""},on:{"click":_vm.contractTypeFunc}},[_c('v-icon',[_vm._v("fas fa-arrow-left")])],1)],1),_c('v-col',{attrs:{"cols":"11"}},[_c('h1',[_vm._v("Agregar video exclusivo")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-row',{staticClass:"pt-8"},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"ContractType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.contractType,"rules":[function (v) { return !!v || 'Item is required'; }],"item-text":"name","item-value":"name","label":"Tipo de contrato","placeholder":"Tipo de contrato","outlined":"","readonly":"","dense":"","return-object":"","prepend-icon":"fas fa-file"},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"BusinessName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre de restaurante","prepend-icon":"fas fa-building","required":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","placeholder":"Nombre de restaurante","outlined":"","dense":""},model:{value:(_vm.postExclusiveVideo.businessName),callback:function ($$v) {_vm.$set(_vm.postExclusiveVideo, "businessName", $$v)},expression:"postExclusiveVideo.businessName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"Amount","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Precio","required":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","prepend-icon":"fas fa-dollar-sign","placeholder":"Precio","outlined":"","dense":"","suffix":"Bs."},model:{value:(_vm.postExclusiveVideo.amount),callback:function ($$v) {_vm.$set(_vm.postExclusiveVideo, "amount", $$v)},expression:"postExclusiveVideo.amount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de publicación","prepend-icon":"fas fa-calendar","readonly":"","outlined":"","dense":""},model:{value:(_vm.postExclusiveVideo.publicated_at),callback:function ($$v) {_vm.$set(_vm.postExclusiveVideo, "publicated_at", $$v)},expression:"postExclusiveVideo.publicated_at"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.postExclusiveVideo.publicated_at),callback:function ($$v) {_vm.$set(_vm.postExclusiveVideo, "publicated_at", $$v)},expression:"postExclusiveVideo.publicated_at"}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"Payment","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Método de pago","prepend-icon":"fas fa-wallet","required":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","placeholder":"Método de pago","outlined":"","dense":""},model:{value:(_vm.postExclusiveVideo.paymentMethod),callback:function ($$v) {_vm.$set(_vm.postExclusiveVideo, "paymentMethod", $$v)},expression:"postExclusiveVideo.paymentMethod"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"VideoUrl","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Url de video","required":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","prepend-icon":"fas fa-link","placeholder":"Url de video","outlined":"","dense":""},model:{value:(_vm.postExclusiveVideo.videoUrl),callback:function ($$v) {_vm.$set(_vm.postExclusiveVideo, "videoUrl", $$v)},expression:"postExclusiveVideo.videoUrl"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Author","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Autor","prepend-icon":"fas fa-at","required":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","placeholder":"Autor","outlined":"","dense":""},model:{value:(_vm.postExclusiveVideo.author),callback:function ($$v) {_vm.$set(_vm.postExclusiveVideo, "author", $$v)},expression:"postExclusiveVideo.author"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Título","prepend-icon":"fas fa-pen","required":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","placeholder":"Título","outlined":"","dense":""},model:{value:(_vm.postExclusiveVideo.title),callback:function ($$v) {_vm.$set(_vm.postExclusiveVideo, "title", $$v)},expression:"postExclusiveVideo.title"}})]}}],null,true)})],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',[_c('v-col',[_c('div',[_vm._v("Previsualización")]),_c('v-card',{staticClass:"scrollbar mx-auto",attrs:{"max-width":"260"}},[_c('v-card-text',{staticClass:"scrollbar"},[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":_vm.thumbnail.image,"id":"bannerImg","aspect-ratio":9/16}},[_c('div',{staticClass:"banner-button"},[_c('v-btn',{attrs:{"icon":"","color":"primary"}},[_c('v-icon',[_vm._v("fas fa-camera")])],1),_c('input',{ref:"file",staticClass:"input-banner",attrs:{"id":"thumbFile","accept":"image/*","type":"file"},on:{"change":_vm.handleThumbnailUpload}})],1)])],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"success","loading":_vm.loading,"type":"submit","disabled":invalid}},[_vm._v(" Guardar ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }